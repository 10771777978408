import React, { FC } from 'react'

import DISCORD from '../../assets/images/logos/social/discord.svg'
import TELEGRAM from '../../assets/images/logos/social/telegram.svg'
import TWITTER from '../../assets/images/logos/social/twitter.svg'
import TWITCH from '../../assets/images/logos/social/twitch.svg'

import styles from './Social.module.scss'

/**
 * Data about a social platform
 */
type SocialData = {
  /**
   * The name of the social platform
   */
  name: string
  /**
   * The icon of the social platform
   */
  icon: string
  /**
   * The link to the social platform
   */
  url: string
}

/**
 * Data for displaying the social links
 */
const SOCIAL_DATA: SocialData[] = [
  {
    name: 'Discord',
    icon: DISCORD,
    url: 'https://discord.gg/spielworks'
  },
  {
    name: 'Telegram',
    icon: TELEGRAM,
    url: 'https://t.me/spielworksofficial'
  },
  {
    name: 'Twitter',
    icon: TWITTER,
    url: 'https://twitter.com/adoptwombat'
  },
  {
    name: 'Twitch',
    icon: TWITCH,
    url: 'https://www.twitch.tv/wombatgamers'
  },
]

/**
 * UI component for showing a link to a social platform/group
 */
const Social: FC<SocialData> = props => (
  <a href={props.url} className={styles['icon']} target='_blank' aria-label={props.name}>
    <img src={props.icon} alt={`${props.name} icon`}/>
  </a>
)

/**
 * UI section containing links to social platforms
 */
export const Socials: FC = () => (
  <div className={styles['socials']}>
    {SOCIAL_DATA.map(social => {
      return <Social key={social.name} name={social.name} icon={social.icon} url={social.url}/> })}
  </div>
)
