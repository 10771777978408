import React, { FC, useEffect } from 'react'
import { getStats } from '../../store/statsSlice'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'

import styles from './About.module.scss'

type StatData = {
  /**
   * The numerical value
   */
  value: number | string
  /**
   * Description/title of the numerical stat
   */
  text: string
}

/**
 * A single statistic
 */
const Stat: FC<StatData> = props => (
  <div role='group'>
    <h3 aria-label={`${props.text} is ${props.value}`}>{props.value}</h3>
    <h4 aria-hidden>{props.text}</h4>
  </div>
)

/**
 * Statistics about dynamic NFTs
 */
export const Stats: FC = () => {
  const dispatch = useAppDispatch()
  const stats = useAppSelector(state => state.stats)

  // Fetch the stats once when the component loads
  useEffect(() => {
    dispatch(getStats())
  }, [])

  const formattedStats = [
    {
      text: 'Total $WOMBAT in reward pool',
      value: stats.totalRemainingPool.toLocaleString(undefined, { maximumFractionDigits: 0 })
    },
    {
      text: 'Tomorrow\'s $WOMBAT payout',
      value: stats.expectedNextDayPayout.toLocaleString(undefined, { maximumFractionDigits: 0 })
    },
    {
      text: 'Total Tree Level-Ups',
      value: stats.totalLevelUps.toLocaleString(undefined, { maximumFractionDigits: 0 })
    }
  ]

  return (
    <div className={styles['stats']}>
      {formattedStats.map(it => <Stat key={it.text} value={it.value} text={it.text}/>)}
    </div>
  )
}
