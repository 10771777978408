export const FAQs = [
  {
    question: 'What are Treevolve NFTs?',
    answer: ['Treevolve is an NFT collection where every NFT starts off as a Seed, followed by a Tree spawn. The more the latter is traded — by volume — the more XP it collects for leveling up. Visually, it transcends into a stunning artwork, with the asset becoming a tall, mystical tree at level 100.']
  },
  {
    question: 'How can I get a Treevolve NFT?',
    answer: [
      'First you need to buy the Treevolve Seed NFT. Treevolve seeds will be available for purchase on November 6th in a drop on the MagicEden launchpad.',
      'Treevolve Trees will be airdropped to Seeds holders on November 9th, 2023.'
    ]
  },
  {
    question: 'How do I collect XP and Level up my Treevolve NFT?',
    answer: ['Treevolve NFTs collect XP according to their trade volume on MagicEden. With each trade the NFT will gain XP depending on how much it was sold for. The Trees will then level up automatically as they gain more XP.']
  },
  {
    question: 'How do I farm $WOMBAT tokens with my Treevolve NFT?',
    answer: ['The trees reward you with $WOMBAT tokens every day depending on their level. If you own at least one Treevolve Tree NFT, you will be able to claim your portion of the $WOMBAT pool every day. We will kick off with 10 million tokens in there, with initially 1% of the remaining pool being distributed to Trees holders every day. On top of that, as the trees get traded, 50% of the royalties on trades will be converted into $WOMBAT and poured into that pool so it doesn’t drain too fast.']
  }
]
