import React, { FC, useRef, useState } from 'react'
import { useAppSelector } from '../hooks/useAppSelector'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { useConnectWallet } from '../hooks/useConnectWallet'
import { useOutsideClick } from '../hooks/useOutsideClick'
import { logout } from '../store/userSlice'
import { Button } from '../elements/Button/Button'

import WOMBAT from '../assets/images/logos/wombat.svg'
import CONNECTED from '../assets/images/icons/link-connected.svg'
import DISCONNECT from '../assets/images/icons/link-disconnected-highlighted.svg'

import styles from './WalletConnection.module.scss'

/**
 * Formats the user address in a style suitable to display in the UI
 */
const formatAddress = (address: string): string => {
  const start = address.slice(0, 4)
  const end = address.slice(address.length - 4)
  return start.concat('...', end)
}

/**
 * UI component for disconnecting the wallet
 */
const Disconnect: FC = () => {
  const dispatch = useAppDispatch()
  const { address } = useAppSelector(state => state.user)
  const [viewDisconnect, setViewDisconnect] = useState(false)
  const disconnectRef = useRef<HTMLButtonElement>(null)

  // Handles clicks outside of the `Disconnect` button component
  useOutsideClick({ refs: [disconnectRef], handleClick: () => setViewDisconnect(false) })

  /**
   * Handles a click to disconnect the wallet
   */
  function handleDisconnect() {
    dispatch(logout())
    setViewDisconnect(false)
  }

  return (
    <div>
      <button aria-label='Open disconnect wallet dropdown' className={styles['wallet-cta']}
        onClick={() => setViewDisconnect(true)}>
        <img src={WOMBAT} alt='wombat logo' aria-hidden/>
        <p aria-label='User wallet address' className={styles['user-address']}>
          {formatAddress(address)}
        </p>
        <img className={styles['icon']} src={CONNECTED} alt='wallet connected'/>
      </button>
      {viewDisconnect && (
        <button aria-label='Disconnect wallet' ref={disconnectRef} onClick={handleDisconnect}
          className={styles['disconnect-btn']}>
          <img src={DISCONNECT} alt='disconnect icon' className={styles['icon']}/>
          <p className='label--large'>Disconnect</p>
        </button>
      )}
    </div>)
}

/**
 * UI component for managing the wallet connection
 */
export const WalletConnection: FC = () => {
  const { connected } = useAppSelector(state => state.user)
  const { connect } = useConnectWallet()

  if (connected === 'YES') {
    return <Disconnect/>
  }

  return <Button onClick={connect}>
    <p className='label--large'>Connect Wallet</p>
  </Button>
}

