@import '../../assets/styles/medias';

.collection {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 24px;
  grid-template-areas: 
    'header button'
    'collection collection';

  &--empty {
    grid-template-areas: 
      'header'
      'no-nfts-message'
  }

  @include mobile-md {
    grid-template-areas: 
      'header'
      'button'
      'collection';
  }
}

.section-title {
  grid-area: header;
}

.empty-message {
  text-align: center;
  grid-area: 'no-nfts-message';
}

.button {
  grid-area: button;
  justify-self: center;
}

.gallery {
  grid-area: collection;
  display: flex;
  overflow-x: scroll;
  gap: 24px;
  width: calc(100vw - 20px);

  @include mobile-md {
    padding: unset;
    flex-wrap: wrap;
    overflow: unset;
    width: 528px;
    justify-content: center;
  }

  @include tablet {
    width: 712px;
  }

  @include desktop-xl {
    width: 952px
  }
}
