import { contract } from '../../config/contract'
import { Token, oneDayLater } from '../../store/tokensSlice'

/**
 * Creates the url from which to fetch the metadata for a given token id
 * @param tokenId The token id as a hex string
 * @example '0x05a4'
 * @returns The url from which to fetch metadata for a token
 */
function formEndpoint(tokenId: string) {
  const urlId = tokenId.slice(2).padStart(64, '0')
  return `https://metadata.wombat.app/polygon/treevolve/${urlId}.json`
}

/**
 * One attribute of a token
 */
type Attribute = {
  /**
   * The attribute name
   * @example 'Level', 'XP'
   */
  trait_type: string
  /**
   * The attribute current value
   * @example 1
   */
  value: number
  /**
   * The attribute maximum value
   * @example 100
   */
  max_value: number
}

/**
 * The token metadata as received from the metadata endpoint
 */
type RawMetadata = {
  /**
   * The token's name
   * @example 'Treevolve Tree'
   */
  name: string
  /**
   * The token's description
   * @example 'From a seed, a Treevolve NFT is born. A sapling at first, but a glorious tree...'
   */
  description: string
  /**
   * A url associated with the token, e.g. the project URL
   * @example 'https://trees.wombat.app'
   */
  external_url: string
  /**
   * The token image
   * @example 'https://metadata.wombat.app/polygon/treevolve/img/1.jpg'
   */
  image: string
  /**
   * A list of attributes for the token
   */
  attributes: Attribute[]
}

/**
 * Fetches the metadata for a given token id
 * @param tokenId The token id as a hex string
 * @example '0x05a4'
 */
export async function fetchMetadata(tokenId: string): Promise<RawMetadata> {
  const endpoint = formEndpoint(tokenId)
  const res = await fetch(endpoint, { cache: 'reload' })
  return res.json()
}

/**
 * Returns the XP values for the token
 */
function getXp(attributes: Attribute[]): { value: number, max: number } | undefined {
  const XP = attributes.find(att => att.trait_type === 'XP')
  if (XP) {
    return { value: XP.value, max: XP.max_value }
  } else {
    return undefined
  }
}

/**
 * Processes the raw token metadata response for use within the dapp
 * @param token The owned {@link RawMetadata} as returned from the metadata url
 * @param tokenId The token id as a hex string
 * @example '0x05a4'
 * @returns The data needed to display a token within the dapp's collection
 */
export function processMetadata(token: RawMetadata, tokenId: string): Token {
  const XP = getXp(token.attributes)
  return {
    contract: contract,
    id: tokenId,
    // TODO - replace this with in-app image
    imageUrl: token.image,
    level: token.attributes.find(att => att.trait_type === 'Level')?.value,
    xp: XP,
    wombatTokens: '0',
    claimableAt: oneDayLater().toString(),
  }
}
