@import '../../../assets/styles/medias';
@import '../../../assets/styles/variables';

.token {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 220px;
  width: 220px;
  gap: 8px;

  @include mobile-md {
    min-width: 160px;
    width: 160px;
  }

  @include desktop-xl {
    min-width: 220px;
    width: 220px;
  }

  .image-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 220px;
    height: 324px;

    @include mobile-md {
      width: 160px;
      height: 234px;
    }
  
    @include desktop-xl {
      width: 220px;
      height: 324px;
    }
  }

  .image {
    width: 100%;
    display: block;
  }

  .top-data {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
  }

  .token-id {
    margin: 8px;
    padding: 4px 8px;
    border-radius: 12px;
    background: var(--colorLightGreyPurpleTranslucent);
    font-size: 12px;
    font-weight: 700;
  }

  .external-link {
    margin: 8px;
    padding: 4px;
    border-radius: 12px;
    background: var(--colorLightGreyPurpleTranslucent);
  }

  .progress {
    &__bar-container {
      position: relative;
    }

    &__bar {
      height: 8px;
      border-radius: 4px;

      &--max {
        background-color: rgba(255, 255, 255, 0.08);
      }

      &--current {
        z-index: 1;
        position: absolute;
        top: 0;
        background-color: var(--colorSuccess);
      }
    }

    &__text {
      font-size: 12px;
      font-weight: 400;
      color: var(--colorTextDisabled);
    }
  }

  .token-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .stats {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text {
    &__level {
      text-wrap: nowrap;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      text-align: center;

      @include mobile-md {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .wombat {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .icon {
    width: 12px;
    height: 12px;
  }
}
