import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { provider, switchChain } from '../provider'

/**
 * The state of the user in the dapp
 */
export type UserState = {
  /**
   * The EVM address of the user
   */
  address: string
  /**
   * If a network state is initialized
   */
  connected: 'NO' | 'IN_PROGRESS' | 'YES'
  /**
   * The the id of the network that is currently selected in the connected wallet
   *
   * 0 if uninitialised otherwise Polygon is supported
   * @example 137 = Polygon
   */
  networkId: number
}

const initialState: UserState = {
  address: '',
  connected: 'NO',
  networkId: 0
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
  /**
   * Action to reset the user state
   */
    logout() {
      return initialState
    }
  },
  extraReducers: builder => {
    builder
      .addCase(connectWallet.fulfilled, (state, action) => {
        state.address = action.payload
        state.connected = 'YES'
      })
      .addCase(connectWallet.rejected, (state, action) => {
        console.error('Error getting the user address', action.error)
      })
      .addCase(determineNetwork.fulfilled, (state, action) => {
        state.networkId = action.payload
      })
      .addCase(determineNetwork.rejected, (state, action) => {
        console.error('Error determining the network', action.error)
      })
      .addCase(switchNetwork.fulfilled, (state, action) => {
        state.networkId = action.payload
      })
      .addCase(switchNetwork.rejected, (state, action) => {
        console.error('Error switching the network', action.error)
      })
  }
})


/**
 * Explicitly requests the user to connect an account to the dApp
 */
export const connectWallet = createAsyncThunk<string>(
  'user/connectWallet',
  () => {
    return provider.send('eth_requestAccounts', [])
      .then(accounts => accounts[0])
  }
)

/**
 * Determines the network from the provider
 */
export const determineNetwork = createAsyncThunk<number>(
  'user/determineNetwork',
  () => {
    return provider.getNetwork()
    // TODO - it shouldn't be a bigint, find a good way to solve this
      .then(network => network.chainId as unknown as number)
  }
)

/**
 * Switch the network to the usable for the dApp
 */
export const switchNetwork = createAsyncThunk<number, number>(
  'user/switchNetwork',
  (networkId) => {
    return switchChain(networkId)
      .then(network => network.chainId)
  }
)

export const { logout } = userSlice.actions

export default userSlice.reducer
