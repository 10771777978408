@import '../assets/styles/medias';

.navbar {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 8px 0;
  width: 100%;
  backdrop-filter: blur(10px);

  @include mobile-md {
    padding: 8px 0;
  }

  @include tablet {
    padding: 16px 0;
  }

  @include desktop-xl {
    padding: 24px 0;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 328px;
    margin: auto;

    @include mobile-md {
      max-width: 528px;
    }
  
    @include tablet {
      max-width: 720px;
    }
  
    @include desktop {
      max-width: 972px;
    }
  
    @include desktop-xl {
      max-width: 1272px;
    }
  }

  .wombat {
    display: flex;
    align-items: center;
    gap: 4px;

    .logo {
      width: 20px;
      height: 20px;

      @include tablet {
        width: 24px;
        height: 24px;
      }

      @include desktop-xl {
        width: 36px;
        height: 36px;
      }
    }
  }

  .menu {
    display: none;

    // for this screen size and larger the menu is visible
    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }

    @include desktop-xl {
      gap: 48px;
    }
  }

  .nav-link {
    padding: 12px 24px;
    border-radius: 24px;
  }

  .nav-link:hover {
    background: #FFFFFF14;
  }

  .nav-link:active {
    background: #FFFFFF1F;
  }
}
