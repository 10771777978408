import React, { FC } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import { Provider } from 'react-redux'
import { store } from './store'
import { initialiseSentry } from './services/sentry/sentry'

const container = document.getElementById('root')

if (!container) {
  throw new Error('Could not find root element')
}

initialiseSentry()

const Root: FC = () => {
  return <Provider store={store}>
    <App />
  </Provider>
}

const root = createRoot(container)
root.render(
  <Root />
)
