@import '../../assets/styles/medias';

.frame {
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  background-image: url('../../assets/images/frame-medium.svg');

  @include mobile-md {
    background-image: url('../../assets/images/frame-gallery-mobile-md.svg');
  }

  @include desktop {
    background-image: url('../../assets/images/frame-large.svg');
  }
}

.frame-container {
  position: relative;
}

.image-container {
  position: absolute;
  margin: 11px;
  top: 0px;
}
