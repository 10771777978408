import cl from 'classnames'
import React, { FC, useMemo } from 'react'
import { TokenXp } from '../../../store/tokensSlice'

import styles from './TokenCard.module.scss'

type Props = {
  /**
   * The experience data for the token
   */
  xp: TokenXp
}

/**
 * A UI component to display information about a token's XP progress through it's level
 */
export const ProgressSection: FC<Props> = props => {

  /**
   * The % of progress through the current level
   */
  const progressPercentage = useMemo(() => (props.xp.value / props.xp.max) * 100, [props.xp])

  return (
    <div className={styles['progress']}>
      <div className={styles['progress__bar-container']}>
        <div className={cl(styles['progress__bar'], styles['progress__bar--max'])}/>
        <div className={cl(styles['progress__bar'], styles['progress__bar--current'])}
          style={{ width: `${progressPercentage}%` }}/>
      </div>
      <p className={styles['progress__text']} aria-label='current tree experience'>
        XP {props.xp.value}/{props.xp.max}
      </p>
    </div>
  )
}
