@import '../../assets/styles/medias';

.socials {
  display: flex;
  gap: 12px;

  @include tablet {
    gap: 24px;
  }
}

.icon {
  height: 36px;
  width: 36px;
}
