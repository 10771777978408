import React, { FC, useMemo, useState } from 'react'
import { Button } from '../../elements/Button/Button'
import { Token, getClaimData } from '../../store/tokensSlice'
import { signAndClaim } from '../../api/wombat/wombat'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { TokenCard } from './TokenCard/TokenCard'
import { features } from '../../feature-flags/feature-flags'

import styles from './Collection.module.scss'

type Props = {
  /**
   * The tokens to display in the user's collection
   */
  tokens: Token[]
  /**
   * The user's EVM address
   */
  address: string
}

/**
 * UI component to display tokens owned by the user
 */
export const OwnedTokens: FC<Props> = props => {
  const dispatch = useAppDispatch()
  const [claimDisabledIds, setClaimDisabledIds] = useState<string[]>([])

  /**
   * Token ids for which there are $WOMBAT available to claim
   */
  const ids = useMemo(() => {
    return props.tokens
    // only tokens where the `claimableAt` is not provided are available to claim
      .filter(tokens => tokens.claimableAt === null)
      .map(tokens => tokens.id)
  }, [props.tokens])

  /**
   * Handles the process for making $WOMBAT claim action
   * @param ids The ids of the tokens for which to make a claim
   */
  function handleClaim(ids: string[]): void {
    // disable claiming whilst a claim is pending
    // TODO - maybe should disable for specific token ids
    setClaimDisabledIds(ids)
    signAndClaim(props.address, ids)
      .then(() => {
        // refetch new claim values
        dispatch(getClaimData(ids))
        // re-enable claiming
        setClaimDisabledIds([])
      })
      .catch(e => {
        console.error(e)
        // re-enable claiming
        setClaimDisabledIds([])
      })
  }

  return (
    <>
      {features.isClaimEnabled() && (
        <Button extraClassName={styles['button']} disabled={claimDisabledIds.length !== 0 || ids.length === 0}
          onClick={() => handleClaim(ids)}>
          Claim All
        </Button>)}
      <div className={styles['gallery']}>
        {props.tokens.map(token => (
          <TokenCard key={token.id} token={token} claim={handleClaim}
            claimDisabled={claimDisabledIds.includes(token.id)}/>
        ))}
      </div>
    </>
  )
}
