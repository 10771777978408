@import '../../assets/styles/medias';

.about {
  display: grid;
  gap: 24px;
  grid-template-areas:
    'info'
    'gallery'
    'stats';

  @include tablet {
    flex-direction: row;
    gap: 48px;
    grid-template-areas:
      'gallery info'
      'gallery stats'
      'gallery .';
  }

  @include desktop-xl {
    gap: 24px;
  }
}

@function gridDimension($cardHeight, $cardNumber, $gapHeight) {
  @return ($cardHeight*$cardNumber) + ($gapHeight*($cardNumber - 1))
};

.grid-container {
  grid-area: gallery;
  position: relative;
  margin: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: 180px;
  grid-template-rows: 260px;
  grid-auto-rows: 0px;
  grid-auto-columns: 0px;
  overflow: hidden;
  gap: 24px;
  width: gridDimension(180, 1, 24) * 1px;
  height: gridDimension(260, 1, 24) * 1px;

  &--superimposed {
    position: absolute;
    top: 0;
  }

  @include mobile-md {
    grid-template-columns: repeat(4, 116px);
    grid-template-rows: repeat(2, 148px);
    width: gridDimension(116, 4, 24) * 1px;
    height: gridDimension(148, 2, 24) * 1px;
  }

  @include tablet {
    grid-template-columns: repeat(2, 116px);
    grid-template-rows: repeat(5, 148px);
    width: gridDimension(116, 2, 24) * 1px;
    height: gridDimension(148, 5, 24) * 1px;
  }

  @include desktop {
    grid-template-columns: repeat(3, 140px);
    grid-template-rows: repeat(5, 200px);
    width: gridDimension(140, 3, 24) * 1px;
    height: gridDimension(200, 5, 24) * 1px;
  }

  @include desktop-xl {
    grid-template-columns: repeat(3, 180px);
    grid-template-rows: repeat(5, 260px);
    width: gridDimension(180, 3, 24) * 1px;
    height: gridDimension(260, 5, 24) * 1px;
  }
}

.image-container {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include mobile-md {
    height: 128px;
  }

  @include desktop {
    height: unset
  }
}

.image {
  width: 100%;

  &--superimposed {
    position: absolute;
  }
}

.info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h4 {
    margin-top: 30px;
  }

  @include tablet {
    margin-top: 48px;
    width: 410px;
    gap: 48px;
  }

  @include desktop {
    margin-top: 72px;
    width: 474px;
    gap: 72px;
  }

  @include desktop-xl {
    margin-top: 128px;
    width: 622px;
    gap: 128px;
  }

  .top-block {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text {
      @include desktop {
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 36px;
      }
    }

    .link-text {
      font-weight: 700px;
      font-size: 1rem;
    }
  }
}

.header {
  &--left-align {
    text-align: left !important;
  }
}

.external-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.icon {
  width: 20px;
  height: 20px;

  &--chevron {
    transform: rotate(90deg);
  }
}

.stats {
  grid-area: stats;
  margin-top: 24px;

  @include mobile-md {
    margin-top: 48px;
  }

  @include tablet {
    margin-top: unset;
  }
}

.fade-in {
  animation: fadeIn 4s ease-in-out 0s 1;
  opacity: 1;
}

.fade-out {
  animation: fadeOut 4s ease-in-out 0s 1;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
