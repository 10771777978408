/**
 * Indicates if the testing panel for Product is enabled
 *
 * !Important - this should not be enabled on production
 *
 * This flag can be enabled at build time with the `TESTING_PANEL_ENABLED` environment
 * variable set to `true`. Otherwise the testing panel will be disabled.
 */
function isTestingPanelEnabled(): boolean {
  return process.env.TESTING_PANEL_ENABLED === 'true'
}

/**
 * Indicates if Google Tag Manager is enabled
 *
 * !Important - this should not be enabled on production
 *
 * This flag can be enabled at build time with the `GOOGLE_TAG_MANAGER_ENABLED`
 * environment variable set to `true`. Otherwise Google Tag Manager will be disabled.
 */
function isGoogleTagManagerEnabled(): boolean {
  return process.env.GOOGLE_TAG_MANAGER_ENABLED === 'true'
}

/**
 * Indicates if the FAQ section is enabled
 *
 * This flag can be enabled at build time with the `FAQ_SECTION_ENABLED` environment
 * variable set to `true`. Otherwise the FAQ section is disabled.
 */
function isFaqSectionlEnabled(): boolean {
  return process.env.FAQ_SECTION_ENABLED === 'true'
}

/**
 * Indicates if the collection links are enabled
 *
 * This flag can be enabled at build time with the `COLLECTION_LINKS_ENABLED` environment
 * variable set to `true`. Otherwise the collection links are disabled.
 */
function isCollectionLinksEnabled(): boolean {
  return process.env.COLLECTION_LINKS_ENABLED === 'true'
}

/**
 * Indicates if claiming is enabled
 *
 * This flag can be enabled at build time with the `IS_CLAIM_ENABLED` environment
 * variable set to `true`. Otherwise viewing claiming will be disabled.
 */
function isClaimEnabled(): boolean {
  return process.env.IS_CLAIM_ENABLED === 'true'
}

/**
 * Feature flags for features which can be enabled and disabled for different environments
 */
export const features = {
  isTestingPanelEnabled,
  isGoogleTagManagerEnabled,
  isFaqSectionlEnabled,
  isCollectionLinksEnabled,
  isClaimEnabled
}
