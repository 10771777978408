/**
 * Whether the Google Tag Manager script is mounted or not
 */
let isMounted = false

/**
 * Mounts the Google Tag Manger script programmatically if it isn't already mounted.
 */
export async function mountGoogleTagManagerScriptOnlyOnce(containerId: string) {
  if (isMounted) {
    console.warn('Attempted to mount the Google Tag Manager script but it is already mounted.')
    return
  }

  await mountGoogleTagManagerScript(containerId)
  isMounted = true
}

/**
 * Mounts the Google Tag Manger script programmatically.
 *
 * This function covers the same steps as the official Google Tag Manager documentation but in a
 * programmatic way instead of hardcoding elements in the html.
 *
 * @see https://developers.google.com/tag-manager/quickstart
 */
function mountGoogleTagManagerScript(containerId: string) {
  return new Promise<void>((resolve) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

    const gtmScript = document.createElement('script')
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${containerId}`
    gtmScript.async = true
    gtmScript.onload = () => { resolve() }

    const firstScriptInHead = document.getElementsByTagName('script')[0]
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    firstScriptInHead.parentNode!.insertBefore(gtmScript, firstScriptInHead)
  })
}
