import React, { FC, useEffect } from 'react'
import { useAppSelector } from './hooks/useAppSelector'
import { useGlobalTick } from './hooks/useGlobalTick'
import { useConnectWallet } from './hooks/useConnectWallet'
import { googleTagManager } from './services/google-tag-manager'
import { Navbar } from './features/NavBar'
import { Collection } from './features/Collection/Collection'
import { About } from './features/About/About'
import { BenefitsTable } from './features/Benefits/BenefitsTable'
import { FAQ } from './features/FAQ/FAQ'
import { Footer } from './features/Footer/Footer'
import { TestingPanel } from './features/TestingPanel/TestingPanel'
import { features } from './feature-flags/feature-flags'

import './App.scss'

export const App: FC = () => {
  const connected = useAppSelector(state => state.user.connected)
  const { connect } = useConnectWallet()
  useGlobalTick()

  useEffect(() => {
    googleTagManager.init()
    if (connected === 'NO') {
      connect()
    }
  }, [])

  return (
    <>
      <TestingPanel />
      <Navbar/>
      <div role='main' className='content'>
        <Collection/>
        <About/>
        <BenefitsTable/>
        {features.isFaqSectionlEnabled() && <FAQ/>}
        <Footer/>
      </div>
    </>
  )
}
