import Decimal from 'decimal.js'

/**
 * Calculates the power of 10 to the input number of decimals
 * @param decimals A decimal precision
 * @returns A power of 10 raised to the input decimal value
 */
function getPower(decimals: number): Decimal {
  return new Decimal(10).pow(decimals)
}

/**
 * Inserts comma separators into a number string
 * @param num The number string to format with commas
 * @returns A number string divided by commas separators
 */
function commify(num: string) {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Removes trailing zeroes from a number string containing a decimal point
 * @param num The number string to format
 * @returns A number string with no trailing zeroes
 */
function trimTrailingZeroes(num: string): string {
  if (!num.includes('.')) {
    // No decimal point, nothing to trim
    return num
  }

  // the first `replace` looks for trailing zeroes after the decimal point and removes them while
  //preserving any significant digits after the decimal point. The second looks for a trailing
  //decimal point at the end of the string and removes it
  return num.replace(/0+$/, '').replace(/\.$/, '')
}

/**
 * The max number of decimals to display
 *
 * Should be used wherever a {@link TokenAmount} is formatted for display in the UI
 */
const DISPLAY_DECIMALS = 2

/**
 * The decimal precision of $WOMBAT tokens
 */
const WOMBAT_DECIMALS = 18

/**
 * Represents a token amount. Token amounts are scaled, meaning they match what we commonly display
 * and what we mean with "1 Token".
 */
export class TokenAmount {
  private constructor(
    /**
     * The numerical value of a token amount
     */
    private readonly amount: Decimal,
    /**
     * The number of decimals used to scale the token amount between wei and decimal quantities
     */
    public readonly decimals: number
  ) {}

  /**
   * Create a {@link TokenAmount} from an unscaled value.
   * @param value The unscaled amount of tokens. This means no decimal places. One token is a 1 with
   *  18 zeroes.
   * @example TokenAmount.fromUnscaledString('1000000000000000000')
   */
  static fromUnscaledString(value: string): TokenAmount {
    return new TokenAmount(new Decimal(value).div(getPower(WOMBAT_DECIMALS)), WOMBAT_DECIMALS)
  }

  /**
   * Get a display version of this amount.
   * @param thousandsSeparator If to use thousands separators
   */
  display(thousandsSeparator: boolean) {
    // Cap the maximum decimals and trim trailing zeroes
    const stringValue = trimTrailingZeroes(this.amount.toFixed(DISPLAY_DECIMALS,
      Decimal.ROUND_FLOOR))
    if (thousandsSeparator) {
      return commify(stringValue)
    } else {
      return stringValue
    }
  }
}
