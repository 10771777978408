import { useAppDispatch } from './useAppDispatch'
import { connectWallet } from '../store/userSlice'
import { getClaimData, getCollectionIds, getMetadata } from '../store/tokensSlice'

/**
 * A hook to provide functionality for connecting the user wallet
 */
export const useConnectWallet = () => {
  const dispatch = useAppDispatch()

  /**
   * Handles a user connecting their wallet
   */
  function connect() {
    dispatch(connectWallet()).unwrap()
      // once a wallet is connected the address is used to fetch owned tokens
      .then(address => {
        dispatch(getCollectionIds(address)).unwrap()
          .then(tokens => {
            dispatch(getMetadata(tokens)).then(() => dispatch(getClaimData(tokens)))
              .catch(e => console.error(e))
          })
          .catch(e => console.error(e))
      })
      .catch(e => console.error(e))
  }

  return {
    connect
  }
}
