import React, { FC } from 'react'

type Props = {
  /**
   * The frame's width
   */
  width: number
  /**
   * The frame's height
   */
  height: number
}

/**
 * An image of a frame as a UI component with adjustable height and width
 */
export const Frame: FC<Props> = ({ width, height }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='5.5' y='5.5' width={width - 11} height={height - 11} stroke='url(#paint0_linear_386_1833)' strokeWidth='3'/>
    <rect x='0.5' y='0.5' width={width - 1} height={height - 1} stroke='url(#paint1_angular_386_1833)'/>
    <defs>
      <linearGradient id='paint0_linear_386_1833' x1='54.5993' y1='34.25' x2='157.237' y2='157.132' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#EECB84'/>
        <stop offset='0.505208' stopColor='#A48037'/>
        <stop offset='1' stopColor='#EECB84'/>
      </linearGradient>
      <radialGradient id='paint1_angular_386_1833' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(81 116) rotate(90) scale(115 77.1792)'>
        <stop offset='0.34015' stopColor='#AA9771'/>
        <stop offset='0.855153' stopColor='#CDB88E'/>
      </radialGradient>
    </defs>
  </svg>
)

