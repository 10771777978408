import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchStats } from '../services/wombat/api'

export type Stats = {
  /**
   * The total $WOMBAT remaining in the reward pool
   * @example 12.0134910203012
   */
  totalRemainingPool: number
  /**
   * The expected $WOMBAT payout for the following day
   * @example 0.120134910203012
   */
  expectedNextDayPayout: number
  /**
   * The total number of times that the dynamic nfts have so far been levelled-up
   * @example 15
   */
  totalLevelUps: number
}

export type StatsState = Stats

const initialState: StatsState = {
  totalRemainingPool: 0,
  expectedNextDayPayout: 0,
  totalLevelUps: 0
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStats.fulfilled, (state, action) => {
        state.totalRemainingPool = action.payload.totalRemainingPool
        state.expectedNextDayPayout = action.payload.expectedNextDayPayout
        state.totalLevelUps = action.payload.totalLevelUps
      })
      .addCase(getStats.rejected, (state, action) => {
        console.error('Error getting the stats', action.error)
      })
  },
})

/**
 * Action to fetch the stats about the trees collection
 */
export const getStats = createAsyncThunk<Stats>(
  'stats/getStats',
  async () => {
    return await fetchStats()
  }
)

export default statsSlice.reducer
