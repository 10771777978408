@import '../../assets/styles/variables';

.legals {
  display: flex;
  gap: 8px;

  p {
    text-decoration: underline;
    font-size: 12px;
    color: var(--colorTextSecondary);
  }
}

.link {
  &:not(:last-child) {
    padding-right: 8px;
    border-right: 0.2rem dotted var(--colorWhiteDisabled);
  }
}
