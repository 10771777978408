import * as Sentry from '@sentry/react'

/**
 * Has Sentry already been initialised
 */
let initialised = false
/**
 * Whether an environment variable exists for the sentry project
 *
 * Local development environment does not use Sentry, therefore Sentry should only be initialised in
 * environments where it should be running. These will habe an env. variable available.
 */
const environmentHasSentry = process.env.SENTRY_PROJECT

/**
 * Initialisation for sentry error reporting
 */
export function initialiseSentry() {
  if (!initialised && environmentHasSentry) {
    Sentry.init({
      dsn: 'https://f30fcd6d5cda4029f42cb71bac8b46b6@o4506343849066496.ingest.sentry.io/4506377088925696',
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      // This sets the sample rate at 10%. You may want to change it to 100% while in development
      // and then sample at a lower rate in production.
      replaysSessionSampleRate: 0.1,
      // If you're not already sampling the entire session, change the sample rate to 100% when
      // sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    })

    initialised = true
  }
}
