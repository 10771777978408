import { provider } from '../../provider'
import { tokensContractAddress } from '../../store/tokensSlice'

const baseUrl = process.env.WOMBAT_API_SERVER ?? 'https://api-test.getwombat.io'
const path = '/dynamic-nfts'

/**
 * Data about the $WOMBAT-claiming values of a token
 *
 * This is data defined within the wombat api, it is not intrinsic to the token itself
 */
export type TokenClaimData = {
  /**
   * The number of claimable $WOMBAT as an unscaled number string in wei
   * @example '100000000000000000000'
   */
  wombatTokens: string
  /**
   * A date string when the tokens become claimable, if there are claimable tokens, otherwise null
   * @example '2023-09-26T13:05:11.013Z' or null
   */
  claimableAt: string | null
}

/**
 * The combined metadata returned for all requested asset ids
 */
export type ClaimData = Record<string, TokenClaimData>

/**
 * Fetches all the data related to claiming provided by $WOMBAT for the owned tokens
 * @param tokenIds The ids of all the tokens for which to fetch metadata
 */
export async function fetchClaimData(tokenIds: string[]): Promise<ClaimData> {
  if (!tokenIds || tokenIds.length === 0) {
    return {}
  } else {
    const endpoint = new URL(path, baseUrl)
    const params = new URLSearchParams({
      contract: tokensContractAddress,
      ids: tokenIds.join(','),
    })
    endpoint.search = params.toString()

    const res = await fetch(endpoint)
    return res.json()
  }
}

/**
 * Provides a signature from the wallet to the claim transaction
 *
 * This is necessary in order to authorise claim transactions
 *
 * @param address The EVM address of the user
 * @returns A signature required in the authorisation of the claim transaction
 */
function sign(address: string): Promise<string> {
  return provider.send('eth_signTypedData_v4', [
    address,
    // Careful, order of fields in this document _is_ relevant! If they are
    // changed the authentication might be not valid.
    JSON.stringify({
      types: {
        Data: [
          { name: 'message', type: 'string' },
        ],
        EIP712Domain: [
          { name: 'chainId', type: 'uint256' },
          { name: 'name', type: 'string' },
          { name: 'verifyingContract', type: 'address' },
          { name: 'version', type: 'string' },
        ],
      },
      domain: {
        chainId: 137,
        name: 'Dynamic NFTs',
        verifyingContract: tokensContractAddress,
        version: '1',
      },
      primaryType: 'Data',
      message: {
        message: 'Verify your address',
      },
    }),
  ])
}

/**
 * Data contained in a successful claim response
 */
type ClaimResponse = {
  /**
   * The total amount of $WOMBAT received from the claim transaction
   * @example '200000000000000000'
   */
  totalWombatClaimed: string
}

/**
 * Executes a claim transaction
 * @param userAddress The EVM address of the user
 * @param tokenIds The ids of all the tokens for which to claim $WOMBAT
 * @returns
 */
export async function signAndClaim(userAddress: string, tokenIds: string[])
: Promise<ClaimResponse> {
  const signature = await sign(userAddress)
  return claim(tokenIds, signature)
}

/**
 * Claims accrued $WOMBAT for the given tokenIds
 *
 * @param tokenIds The ids of all the tokens for which to claim $WOMBAT
 * @param signature The user wallet signature required in the claim transaction
 */
async function claim(tokenIds: string[], signature: string): Promise<ClaimResponse> {
  const endpoint = new URL(`${path}/claim`, baseUrl)

  const data = {
    contract: tokensContractAddress,
    ids: tokenIds
  }
  const res = await fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Signature ${signature}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  })
  return res.json()
}
