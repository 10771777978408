import { features } from '../../feature-flags/feature-flags'
import { mountGoogleTagManagerScriptOnlyOnce } from './mount-gtm-script'

/**
 * Configuration for the Google Tag Manager
 */
export const config = {
  isEnabled: features.isGoogleTagManagerEnabled(),
  containerId: 'G-3DN4J77NYN',
}

/**
 * Initialises the Google Tag Manager
 */
function init() {
  if (!config.isEnabled) {
    return
  }

  return mountGoogleTagManagerScriptOnlyOnce(config.containerId)
}

export const googleTagManager = {
  init,
}
