import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTokenIds } from '../api/alchemy/alchemy'
import { ClaimData, TokenClaimData, fetchClaimData } from '../api/wombat/wombat'
import { fetchMetadata, processMetadata } from '../api/metadata/metadata'

/**
 * Contract address of the EVM tokens to display in the gallery
 */
export const tokensContractAddress = process.env.TREES_CONTRACT_ADDRESS ?? ''

/**
 * The experience values for the token
 */
export type TokenXp = {
  /**
   * The current value of the XP
   */
  value: number
  /**
   * The maximum value of the XP for this level
   */
  max: number
}

/**
 * Select data from a token
 *
 * This is data which is needed for an token within the app,
 * e.g. to populate the UI or interact with wombat api
 */
export type Token = TokenClaimData & {
  /**
   * The token collection contract
   */
  contract: string
  /**
   * The token id as a hex value
   * @example 0x04
   */
  id: string
  /**
   * The image for the token
   */
  imageUrl: string
  /**
   * The token's level
   */
  level?: number
  /**
   * The experience values for the token
   */
  xp?: TokenXp
}

/**
 * Returns a Date one day later than now or the provided Date
 */
export function oneDayLater(date = new Date()): number {
  const newDate = new Date(date)
  return newDate.setDate(newDate.getDate() + 1)
}

// const hexPattern = /^(0x)?[0-9A-Fa-f]+$/

// function numToUint256(val: string): bigint {
//   let preparedVal = val
//   if (hexPattern.test(val)) {
//     const cleanedHexString = val.startsWith('0x') ? val.slice(2) : val
//     preparedVal = '0x' + cleanedHexString
//   }
//   return BigInt(preparedVal)
// }

/**
 * Assigns wombat claim data to the corresponding token
 * @param tokens The tokens owned by the user
 * @param claimData The wombat metadata for each token
 * @returns A token complete with associated metadata
 */
function mergeTokensWithClaimData(tokens: Token[], claimData: ClaimData): Token[] {
  return tokens.map(token => {
    // TODO - can be converted to bigint and used to index metadata?
    const decimalTokenId = parseInt(token.id, 16)
    const tokenData = claimData[decimalTokenId]
    if (tokenData) {
      return {
        id: token.id,
        contract: token.contract,
        level: token.level,
        xp: token.xp,
        imageUrl: token.imageUrl,
        wombatTokens: tokenData.wombatTokens,
        claimableAt: tokenData.claimableAt
      }
    } else {
      return token
    }
  })
}

/**
 * The state of the `tokenSlice`
 */
export type TokensState = {
  /**
   * The ids of the tokens owned by the user
   */
  tokenIds: string[]
  /**
   * The tokens owned by the user
   */
  tokens: Token[]
}

const initialState: TokensState = {
  tokenIds: [],
  tokens: []
}

const tokensSlice = createSlice({
  name: 'tokens',
  initialState: initialState,
  reducers: {
    /**
     * Action to reset the tokens state
     */
    reset() {
      return initialState
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCollectionIds.fulfilled, (state, action) => {
        state.tokenIds = action.payload
      })
      .addCase(getCollectionIds.rejected, (state, action) => {
        console.error('Error getting the user\'s tokens ids', action.error)
      })
      .addCase(getMetadata.fulfilled, (state, action) => {
        state.tokens = action.payload
      })
      .addCase(getMetadata.rejected, (state, action) => {
        console.error('Error getting the user\'s token metadata', action.error)
      })
      .addCase(getClaimData.fulfilled, (state, action) => {
        const updatedAssets = mergeTokensWithClaimData(state.tokens, action.payload)
        state.tokens = updatedAssets
      })
      .addCase(getClaimData.rejected, (state, action) => {
        console.error('Error getting the metadata for tokens', action.error)
      })
  }
})

/**
 * Action to fetch the ids for the user's dynamic tokens
 */
export const getCollectionIds = createAsyncThunk<string[], string>(
  'tokens/getCollectionIds',
  async (account) => {
    return await getTokenIds(account)
  }
)

/**
 * Action to fetch the wombat metadata for dynamic tokens
 */
export const getMetadata = createAsyncThunk<Token[], string[]>(
  'tokens/getMetadata',
  async (tokenIds) => {
    const metadata = tokenIds.map(async id => {
      const raw = await fetchMetadata(id)
      return processMetadata(raw, id)
    })
    return await Promise.all(metadata)
  }
)

/**
 * Action to fetch the data about claiming $WOMBAT for tokens
 */
export const getClaimData = createAsyncThunk<ClaimData, string[]>(
  'tokens/getClaimData',
  async (tokenIds: string[]) => {
    return await fetchClaimData(tokenIds)
  }
)

export default tokensSlice.reducer
