import { FC, useState } from 'react'
import { FAQs } from './questions'

import CHEVRON from '../../assets/images/icons/chevron.svg'

import styles from './FAQ.module.scss'

type Question = {
  /**
   * The question for an FAQ entry
   * @example 'What is a dynamic NFT?'
   */
  question: string
  /**
   * The answer for an FAQ entry
   * @example ['A dynamic NFT is an NFT which changes']
   */
  answers: string[]
}

/**
 * A single entry (question and answer) in the FAQ
 */
const Question: FC<Question> = props => {
  const [open, setOpen] = useState(false)

  return (
    <li className={styles['faq-item']}>
      <button aria-label={props.question} onClick={() => setOpen(!open)}
        className={styles['question']}>
        <p>{props.question}</p>
        <img src={CHEVRON} className={styles[open ? 'chevron--up' : 'chevron--down']} alt=''/>
      </button>
      {open && props.answers.map(answer => <p className={styles['answer']}>{answer}</p>)}
    </li>
  )
}

/**
 * Page section containing frequently asked questions
 */
export const FAQ: FC = () => (
  <section id='faq' role='region' aria-labelledby='faq-heading' className='default-section'>
    <h2 id='faq-heading'>FAQ</h2>
    <ul className={styles['faq-list']}>
      {FAQs.map(faq => (
        <Question key={faq.question} question={faq.question} answers={faq.answer} />
      ))}
    </ul>
  </section>
)
