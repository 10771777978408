import { createSlice } from '@reduxjs/toolkit'

export type GlobalState = {
  /**
   * A count with which to synchronise ticks across the application
   */
  tick: number
}

const initialState: GlobalState = { tick: 0 }

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    /**
     * Adds '1' to the global tick
     */
    updateTick(state) {
      state.tick = state.tick + 1
    },
  }
})

export const { updateTick } = globalSlice.actions

export default globalSlice.reducer
