@import '../../assets/styles/medias';

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  @include tablet {
    align-items: center;
    width: 315px;
  }
}
