import React, { FC } from 'react'
import { WalletConnection } from './WalletConnection'
import { useAppSelector } from '../hooks/useAppSelector'
import { features } from '../feature-flags/feature-flags'

import WOMBAT from '../assets/images/logos/wombat.svg'

import styles from './NavBar.module.scss'

/**
 * Top menu UI component containing navigational and login functionalities
 */
export const Navbar: FC = () => {
  const connected = useAppSelector(state => state.user.connected === 'YES')
  return (
    <div role='banner' className={styles['navbar']} data-testid='navbar-main'>
      <div className={styles['content']}>
        <span className={styles['wombat']}>
          <img src={WOMBAT} alt='wombat logo' className={styles['logo']}/>
          <h4>Wombat</h4>
        </span>
        <ul role='navigation' aria-label='site-navigation-links' className={styles['menu']}>
          {connected && <li className={styles['nav-link']}><a href='#collection'>
            <p className='label--large'>My collection</p>
          </a></li>}
          <li className={styles['nav-link']}><a href='#about'>
            <p className='label--large'>About</p>
          </a></li>
          <li className={styles['nav-link']}><a href='#benefits'>
            <p className='label--large'>Benefits</p>
          </a></li>
          {features.isFaqSectionlEnabled() && <li className={styles['nav-link']}><a href='#faq'>
            <p className='label--large'>FAQ</p>
          </a></li>}
        </ul>
        <WalletConnection/>
      </div>
    </div>
  )
}
