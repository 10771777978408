@import '../../assets/styles/medias';
@import '../../assets/styles/variables';

.faq-list {
  display: flex;
  flex-direction: column;
}

.faq-item {
  padding: 24px 0;
  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--colorWhiteDisabled);
  }
}

.question {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: left;

  @include desktop {
    font-size: 18px;
    line-height: 24px;
  }
}

.answer {
  padding-top: 16px;
  font-size: 14px;
  line-height: 21px;

  @include desktop {
    font-size: 16px;
    line-height: 24px;
  }
}

.chevron {
  &--down {
    transform: rotate(180deg);
  }
}
