import { useEffect } from 'react'
import { useAppDispatch } from './useAppDispatch'
import { updateTick } from '../store/globalSlice'

/**
 * A hook to update the global `tick` counter every second
 */
export const useGlobalTick = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(updateTick())
    }, 1000)
    return () => clearInterval(interval)
  }, [])
}
