import cl from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { Frame } from '../../elements/Frame/Frame'
import images from '../../assets/images/trees'

import styles from './About.module.scss'

/**
 * Generates a random number between 1 and 100 inclusive
 */
function randomNumber(): number {
  return Math.floor(Math.random() * 100) + 1
}

/**
 * UI component displaying a single place for an image
 */
const Image: FC = () => {
  const tick = useAppSelector(state => state.global.tick)
  const [treeOneVisible, setTreeOneVisible] = useState(true)
  const [treeTwoVisible, setTreeTwoVisible] = useState(false)
  const [fadeOutOne, setFadeOutOne] = useState(true)
  const [fadeOutTwo, setFadeOutTwo] = useState(false)
  const [treeOneUrl, setTreeOneUrl] = useState(images[randomNumber()])
  const [treeTwoUrl, setTreeTwoUrl] = useState(images[randomNumber()])
  const [isTransitioning, setIsTransitioning] = useState(false)

  useEffect(() => {
    // have a chance that the images transition
    const randomFadeOut = randomNumber() < 70
    if (randomFadeOut && !isTransitioning) {
      setIsTransitioning(true)
      // set fade out transition for correct tree
      if (treeOneVisible) {
        setFadeOutOne(true)
        setFadeOutTwo(false)
      } else if (treeTwoVisible) {
        setFadeOutTwo(true)
        setFadeOutOne(false)
      }

      // after 4000ms, replace the invisible tree with a new image
      setTimeout(() => {
        if (treeOneVisible && fadeOutOne) {
          setTreeTwoVisible(true)
          setTreeOneVisible(false)
          setTreeOneUrl(images[randomNumber()])
        } else if (treeTwoVisible && fadeOutTwo) {
          setTreeOneVisible(true)
          setTreeTwoVisible(false)
          setTreeTwoUrl(images[randomNumber()])
        }
        setIsTransitioning(false)
      }, 5000)
    }
  }, [tick])

  return (
    <>
      <img className={cl(
        styles['image'],
        styles[fadeOutOne ? 'fade-out' : 'fade-in']
      )} src={treeOneUrl} alt='tree nft' aria-hidden/>
      <img className={cl(
        styles['image'],
        styles['image--superimposed'],
        styles[fadeOutTwo ? 'fade-out' : 'fade-in']
      )} src={treeTwoUrl} alt='tree nft' aria-hidden/>
    </>
  )
}

/**
 * UI component of a grid displaying images of various dynamic tokens
 */
export const ImageGrid: FC = () => {
  const grid: undefined[] = Array(20).fill(undefined)

  return (
    <div className={styles['grid-container']}>
      <div className={styles['image-grid']}>
        {grid.map((_, i) => (
          <Frame key={i}>
            <div className={styles['image-container']}>
              <Image />
            </div>
          </Frame>
        ))}
      </div>
    </div>
  )
}
