import { AnyAction, configureStore, ThunkMiddleware } from '@reduxjs/toolkit'
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'
import userReducer, { UserState } from './userSlice'
import globalReducer, { GlobalState } from './globalSlice'
import tokenReducer, { TokensState } from './tokensSlice'
import testingReducer, { TestingState } from './testingSlice'
import statsReducer, { StatsState } from './statsSlice'

export type RootState = {
  /**
   * The user's data
   */
  user: UserState
  /**
   * Top-level state which should have a single source of truth throughout the application
   */
  global: GlobalState
  /**
   * Data about the user's owned tokens
   */
  tokens: TokensState
  /**
   * Statistics about the dynamic NFTs
   */
  stats: StatsState
  /**
   * Configuration set for testing various landing page states
   */
  testing: TestingState
}

type Store = ToolkitStore<RootState, AnyAction, [ThunkMiddleware<RootState, AnyAction, undefined>]>

/**
 * The redux store, built out of all combined reducers
 */
export const store: Store = configureStore<RootState>({
  reducer: {
    user: userReducer,
    global: globalReducer,
    tokens: tokenReducer,
    stats: statsReducer,
    testing: testingReducer
  },
})

/**
 * `AppDispatch` type is inferred from the store itself
 */
export type AppDispatch = typeof store.dispatch

/**
 * Type to use in createAsyncThunk<A, B, AsyncThunkOptions> to get correctly typed access to the
 * state and dispatch from thunkAPI
 */
export type AsyncThunkOptions = { state: RootState, dispatch: AppDispatch }
