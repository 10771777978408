import React, { FC, useMemo, useState } from 'react'
import { Token } from '../../../store/tokensSlice'
import { Button } from '../../../elements/Button/Button'
import { DynamicFrame } from '../../../elements/Frame/DynamicFrame'
import { TokenAmount } from '../../../numbers/TokenAmount'
import { Link } from '../../../elements/Link/Link'
import { contract } from '../../../config/contract'
import { features } from '../../../feature-flags/feature-flags'

import WOMBAT from '../../../assets/images/logos/wombat.svg'
import EXTERNAL_LINK from '../../../assets/images/icons/linkout.svg'
import images from '../../../assets/images/trees'

import styles from './TokenCard.module.scss'
import { ProgressSection } from './ProgressSection'

function getMagicEdenLink(decimalId: number) {
  return `https://magiceden.io/item-details/polygon/${contract}/${decimalId}`
}

type Props = {
  /**
   * The token to be rendered in the card
   */
  token: Token
  /**
   * Whether claiming is disabled or not
   */
  claimDisabled: boolean
  /**
   * Function to execute a claim
   */
  claim: (id: string[]) => void
}

/**
 * UI component for displaying a single user-owned token
 */
export const TokenCard: FC<Props> = props => {
  // Loading status to force a frame rerender to update the size when the image is loaded
  const [imageLoaded, setImageLoaded] = useState(false)

  /**
   * Tokens as a scaled value for readable display
   */
  const scaledTokens: string = useMemo(() => {
    return TokenAmount.fromUnscaledString(props.token.wombatTokens).display(true)
  }, [props.token.wombatTokens])

  /**
   * The tokenId as a decimal value
   */
  const decimalId: number = useMemo(() => parseInt(props.token.id, 16), [props.token.id])

  /**
   * The url of the item page on Magic Eden
   */
  const magicEdenLink = useMemo(() => getMagicEdenLink(decimalId), [decimalId])

  return (
    <div key={props.token.id} className={styles['token']}>
      <DynamicFrame loaded={imageLoaded}>
        <div className={styles['top-data']}>
          <p aria-label='tree token number' className={styles['token-id']}>
            <span aria-hidden>#</span>{decimalId}
          </p>
          <Link url={magicEdenLink}>
            <img alt='Open tree on Magic Eden' className={styles['external-link']} src={EXTERNAL_LINK}/>
          </Link>
        </div>
        <img src={images[props.token.level ?? 1]} className={styles['image']} alt='user-owned tree nft'
          onLoad={() => setImageLoaded(true)}/>
      </DynamicFrame>
      <div className={styles['token-data']}>
        <div className={styles['stats']}>
          <span className={styles['text__level']} aria-label='tree level'>
            Lvl {props.token.level ?? '?'}
          </span>
          <span id='tokens-collected' className={styles['wombat']}>
            <img src={WOMBAT} className={styles['icon']} alt=''/>
            <p className='label--medium' aria-label='claimable WOMBAT tokens'>{scaledTokens}</p>
          </span>
        </div>
        {features.isClaimEnabled() && <Button type='secondary'
          disabled={props.claimDisabled || props.token.claimableAt !== null || scaledTokens === '0'}
          onClick={() => props.claim([props.token.id])}>
          <p className='label--large'>Claim</p>
        </Button>}
      </div>
      {props.token.xp && <ProgressSection xp={props.token.xp}/>}
    </div>
  )
}
