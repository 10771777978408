import cl from 'classnames'
import React, { FC } from 'react'
import { Stats } from './Stats'
import { ImageGrid } from './ImageGrid'
import { Link } from '../../elements/Link/Link'

import CHEVRON from '../../assets/images/icons/chevron.svg'
import ATOMICHUB from '../../assets/images/logos/atomichub.svg'
import JUMP_SEA from '../../assets/images/logos/jump-trade.png'
import MAGIC_EDEN from '../../assets/images/logos/magic-eden.svg'

import styles from './About.module.scss'

/**
 * Page section explaining and displaying the concept of dynamic tokens (nfts)
 */
export const About: FC = () => (
  <section role='region' aria-label='About' id='about' className={styles['about']}>
    <ImageGrid/>
    <div className={styles['info']}>
      <div className={styles['top-block']}>
        <h2 id='about-heading' className={styles['header--left-align']}>
          Grow your own NFT tree with Treevolve
        </h2>
        <p className={styles['text']}>
          Treevolve is Wombat's next ambitious endeavour in the domain of NFTs. Starting off as
          seeds, Treevolve spawn tiny non-fungible trees. The more the Treevolve NFTs get
          traded, collecting XP, the higher levels they reach — meaning, they mine more $WOMBAT
          tokens.
        </p>
        <p className={styles['text']}>
          The Trees gain XP with every trade on AtomicHub, MagicEden or Jump.Trade with royalties
          enabled, based on the NFT trade price. The Trees will automatically level up once they've
          gained enough XP to reach the next level. Higher level Trees have more impressive
          visuals and are receive a bigger share of the daily rewards pool. See the Level-Up
          overview table below for a detailed breakdown.
        </p>
        <span className={styles['external-links']}>
          <Link type='button-like--secondary' aria-labelledby='atomic-hub-link'
            url='https://atomichub.io/market?primary_chain=polygon-mainnet&edge_collection_id=94198a95-ec38-101a-5464-cc2a68ce1855&blockchain=polygon-mainnet&order=desc&sort=created_at#sales'>
            <img className={styles['icon']} src={ATOMICHUB} alt=''/>
            <p id='atomic-hub-link' className={styles['link-text']}>Check on AtomicHub</p>
            <img className={cl(styles['icon'], styles['icon--chevron'])} src={CHEVRON} alt=''/>
          </Link>
          <Link type='button-like--secondary' aria-labelledby='magic-eden-link'
            url='https://magiceden.io/collections/polygon/0x939821Fd096b4e4F67f369AF67cf9411b1A28160'>
            <img className={styles['icon']} src={MAGIC_EDEN} alt=''/>
            <p id='magic-eden-link' className={styles['link-text']}>Check on MagicEden</p>
            <img className={cl(styles['icon'], styles['icon--chevron'])} src={CHEVRON} alt=''/>
          </Link>
          <Link type='button-like--secondary' aria-labelledby='jump-trade-link'
            url='https://pro.jump.trade/collection/polygon/0x939821fd096b4e4f67f369af67cf9411b1a28160'>
            <img className={styles['icon']} src={JUMP_SEA} alt=''/>
            <p id='jump-trade-link' className={styles['link-text']}>Check on Jump.Trade</p>
            <img className={cl(styles['icon'], styles['icon--chevron'])} src={CHEVRON} alt=''/>
          </Link>
        </span>
      </div>
    </div>
    <Stats/>
  </section>
)
