@import '../../assets/styles/_typography';
@import '../../assets/styles/_variables';
@import '../../assets/styles/_medias';

.button {
  border-radius: 24px;
  padding: 12px 24px;
  background: var(--gradient-primary);
  font-weight: 700;

  @include tablet {
    width: unset;
  }

  @include desktop-xl {
    height: 48px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    background: var(--colorSurfaceDisabled) !important;
    color: var(--colorTextDisabled);
    cursor: not-allowed;
  }
}

.secondary {
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
  
  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}
