@import '../../assets/styles/_variables';
@import '../../assets/styles/_medias';

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 280px;
}

.default {
  font-size: 14px;
  font-weight: 400;
  color: var(--colorHighlight);
  text-decoration: underline;
  text-underline-offset: 5px;
}

.button-like {
  &--primary {
    border-radius: 24px;
    padding: 12px 24px;
    background: var(--gradient-primary);
    font-weight: 700;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }

  &--secondary {
    border: 1px solid var(--colorWhite);
    background: var(--colorSurfaceDark);
    padding: 12px 24px;
    border-radius: 24px;
    
    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }
}
