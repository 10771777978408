import { tokensContractAddress } from '../../store/tokensSlice'
import { PolygonAsset } from './alchemy-types'

/**
 * The base url for the alchemy API
 */
const alchemyApiUrl = 'https://polygon-mainnet.g.alchemy.com'
const alchemyApiKey = process.env.ALCHEMY_API_KEY ?? ''

type Method = 'GET' | 'POST'

/**
 * Sends a request to a specified endpoint of the alchemy API
 * @param endpoint the endpoint for the request
 * @param method the HTTP method should be used for the request, defaults to `GET`
 * If passed `GET` then the `data` argument is ignored
 * @param data data to send in the request body
 */
const sendRequestToAlchemyApi = (
  endpoint: string, method: Method = 'GET', data?: Record<string, unknown>,
) => {
  const url = alchemyApiUrl + endpoint

  // Request with method GET can't contain data in body.
  if (method === 'GET') return fetch(url)

  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

/**
 * Returns all owned NFTs for the user by the polygon address
 * alchemy documentation: https://docs.alchemy.com/reference/getnfts
 *
 * @param polygonAddress Polygon address the NFTs should be gotten for
 * @param pageKey Uses by the API for pagination. If the first request returns value
 * in the `pageKey` it means there are more NFT that should be requested and they are requested.
 * Basically, waits until the `pageKey` is undefined and after that returns all NFTs.
 */
async function getPolygonAssets(polygonAddress: string, pageKey?: string): Promise<PolygonAsset[]> {
  const nfts: PolygonAsset[] = []
  const params = new URLSearchParams({
    owner: polygonAddress,
    'contractAddresses[]': tokensContractAddress,
    pageSize: '100',
  })

  // If pageKey exists it means there are more NFT that should be requested
  if (pageKey) {
    params.append('pageKey', pageKey)
  }

  // Request first batch of NFTs
  const result = await sendRequestToAlchemyApi(`/v2/${alchemyApiKey}/getNFTs?${params.toString()}`)
  const firstBatch = await result.json()
  nfts.push(...firstBatch.ownedNfts)

  // If pageKey exists then there are more NFTs which have to be requested
  if (firstBatch.pageKey) {
    const restBatch = await getPolygonAssets(
      polygonAddress, firstBatch.pageKey,
    )
    nfts.push(...restBatch)
  }

  return nfts
}

/**
 * Returns all token ids owned by a polygon address
 *
 * @param polygonAddress Polygon address the NFTs should be fetched for
 * @param pageKey Used by the API for pagination.
 */
export async function getTokenIds(polygonAddress: string, pageKey?: string): Promise<string[]> {
  return (await getPolygonAssets(polygonAddress, pageKey)).map(nft => nft.id.tokenId)
}
