import { BrowserProvider } from 'ethers'

/**
 * The ethers provider to communicate with the chain.
 */
// This should use the type "ethers.providers.JsonRpcProvider | undefined", but this makes
// interacting with it rather cumbersome. Instead it is assumed the app checks once in the beginning
// that this exists and renders an error otherwise.

export let provider: BrowserProvider

if (window.ethereum) {
  // Allow any network - so we can hint the user to switch the network if necessary
  provider = new BrowserProvider(window.ethereum)
}

/**
 * Parameters to add a network to a provider, for the RPC method 'wallet_addEthereumChain'
 */
type AddNetworkParams = {
  /**
   * The chain id of the network to add. Must be in hexadecimal with a leading '0x'
   */
  chainId: string
  /**
   * The name of the chain
   */
  chainName: string
  /**
   * Description of the native currency of the chain
   */
  nativeCurrency: {
    /**
     * The name of the currency.
     * Note: This seems to be ignored by Metamask
     */
    name: string
    /**
     * The symbol of the currency
     */
    symbol: string
    /**
     * The number of digits of the currency
     */
    decimals: number
  }
  /**
   * RPC URLs for the chain
   */
  rpcUrls?: string[]
  /**
   * Block explorer URLs for the chain
   */
  blockExplorerUrls?: string[]
}

/**
 * Data for the EVM networks
 * https://www.coincarp.com/chainlist/
 */
export const NETWORK_DATA: Record<number, AddNetworkParams> = {
  137: { // Polygon main network
    chainId: '0x89',
    chainName: 'Matic Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  80001: { // Mumbai test network
    chainId: '0x13881',
    chainName: 'Polygon Testnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  }
}

/**
 * Request that the provider switches the network to the chain with the given id. If the provider
 * does not know the chain it will be added to it.
 *
 * Only works for chain ids registered in {@link NETWORK_DATA}.
 * @param chainId The chain id to switch to.
 *
 * @return The selected network. provider.on('network', func) will be triggered for Metamask but not
 * for wombat wallet. Thus, we want to set network manually to the store.
 */
export async function switchChain(chainId: number) {
  const networkData = NETWORK_DATA[chainId]
  if (!networkData) {
    throw new Error(`Unsupported chain ${chainId}`)
  }

  await provider.send(
    'wallet_addEthereumChain',
    [networkData]
  )

  // returned when the request is accepted or rejected
  return {
    chainId,
    name: networkData.chainName,
  }
}
