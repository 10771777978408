import React, { FC, PropsWithChildren } from 'react'

import styles from './Frame.module.scss'

/**
 * A static frame design which can wrap token images
 */
export const Frame: FC<PropsWithChildren> = props => {
  return (
    <div className={styles['frame']}>
      {props.children}
    </div>
  )
}
