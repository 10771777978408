import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TestingState = {
  /**
   * A UTC timestamp to set the application to
   */
  atUTCTimestamp?: string
}

/**
 * The initial state for the testing slice
 */
const initialState: TestingState = { atUTCTimestamp: undefined }

const testingSlice = createSlice({
  name: 'testing',
  initialState,
  reducers: {
    /**
     * Set a timestamp at which to test the application state
     */
    setUTCTimestamp(state, action: PayloadAction<string>) {
      state.atUTCTimestamp = action.payload
    }
  }
})

export const { setUTCTimestamp } = testingSlice.actions

export default testingSlice.reducer
