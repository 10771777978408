import cl from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import styles from './Link.module.scss'

type Props = PropsWithChildren & {
  /**
   * The destination for the link
   */
  url: string
  /**
   * The style of the link
   */
  type?: 'button-like--primary' | 'button-like--secondary'
}

/**
 * The UI component for a link
 */
export const Link: FC<Props> = props => (
  <a href={props.url} target='_blank'
    className={cl(styles['link'], props.type ? styles[props.type] : styles['default'])}>
    {props.children}
  </a>
)
