import Image1 from './001.jpg'
import Image2 from './002.jpg'
import Image3 from './003.jpg'
import Image4 from './004.jpg'
import Image5 from './005.jpg'
import Image6 from './006.jpg'
import Image7 from './007.jpg'
import Image8 from './008.jpg'
import Image9 from './009.jpg'
import Image10 from './010.jpg'
import Image11 from './011.jpg'
import Image12 from './012.jpg'
import Image13 from './013.jpg'
import Image14 from './014.jpg'
import Image15 from './015.jpg'
import Image16 from './016.jpg'
import Image17 from './017.jpg'
import Image18 from './018.jpg'
import Image19 from './019.jpg'
import Image20 from './020.jpg'
import Image21 from './021.jpg'
import Image22 from './022.jpg'
import Image23 from './023.jpg'
import Image24 from './024.jpg'
import Image25 from './025.jpg'
import Image26 from './026.jpg'
import Image27 from './027.jpg'
import Image28 from './028.jpg'
import Image29 from './029.jpg'
import Image30 from './030.jpg'
import Image31 from './031.jpg'
import Image32 from './032.jpg'
import Image33 from './033.jpg'
import Image34 from './034.jpg'
import Image35 from './035.jpg'
import Image36 from './036.jpg'
import Image37 from './037.jpg'
import Image38 from './038.jpg'
import Image39 from './039.jpg'
import Image40 from './040.jpg'
import Image41 from './041.jpg'
import Image42 from './042.jpg'
import Image43 from './043.jpg'
import Image44 from './044.jpg'
import Image45 from './045.jpg'
import Image46 from './046.jpg'
import Image47 from './047.jpg'
import Image48 from './048.jpg'
import Image49 from './049.jpg'
import Image50 from './050.jpg'
import Image51 from './051.jpg'
import Image52 from './052.jpg'
import Image53 from './053.jpg'
import Image54 from './054.jpg'
import Image55 from './055.jpg'
import Image56 from './056.jpg'
import Image57 from './057.jpg'
import Image58 from './058.jpg'
import Image59 from './059.jpg'
import Image60 from './060.jpg'
import Image61 from './061.jpg'
import Image62 from './062.jpg'
import Image63 from './063.jpg'
import Image64 from './064.jpg'
import Image65 from './065.jpg'
import Image66 from './066.jpg'
import Image67 from './067.jpg'
import Image68 from './068.jpg'
import Image69 from './069.jpg'
import Image70 from './070.jpg'
import Image71 from './071.jpg'
import Image72 from './072.jpg'
import Image73 from './073.jpg'
import Image74 from './074.jpg'
import Image75 from './075.jpg'
import Image76 from './076.jpg'
import Image77 from './077.jpg'
import Image78 from './078.jpg'
import Image79 from './079.jpg'
import Image80 from './080.jpg'
import Image81 from './081.jpg'
import Image82 from './082.jpg'
import Image83 from './083.jpg'
import Image84 from './084.jpg'
import Image85 from './085.jpg'
import Image86 from './086.jpg'
import Image87 from './087.jpg'
import Image88 from './088.jpg'
import Image89 from './089.jpg'
import Image90 from './090.jpg'
import Image91 from './091.jpg'
import Image92 from './092.jpg'
import Image93 from './093.jpg'
import Image94 from './094.jpg'
import Image95 from './095.jpg'
import Image96 from './096.jpg'
import Image97 from './097.jpg'
import Image98 from './098.jpg'
import Image99 from './099.jpg'
import Image100 from './100.jpg'

const images: Record<number, string> = {
  1: Image1,
  2: Image2,
  3: Image3,
  4: Image4,
  5: Image5,
  6: Image6,
  7: Image7,
  8: Image8,
  9: Image9,
  10: Image10,
  11: Image11,
  12: Image12,
  13: Image13,
  14: Image14,
  15: Image15,
  16: Image16,
  17: Image17,
  18: Image18,
  19: Image19,
  20: Image20,
  21: Image21,
  22: Image22,
  23: Image23,
  24: Image24,
  25: Image25,
  26: Image26,
  27: Image27,
  28: Image28,
  29: Image29,
  30: Image30,
  31: Image31,
  32: Image32,
  33: Image33,
  34: Image34,
  35: Image35,
  36: Image36,
  37: Image37,
  38: Image38,
  39: Image39,
  40: Image40,
  41: Image41,
  42: Image42,
  43: Image43,
  44: Image44,
  45: Image45,
  46: Image46,
  47: Image47,
  48: Image48,
  49: Image49,
  50: Image50,
  51: Image51,
  52: Image52,
  53: Image53,
  54: Image54,
  55: Image55,
  56: Image56,
  57: Image57,
  58: Image58,
  59: Image59,
  60: Image60,
  61: Image61,
  62: Image62,
  63: Image63,
  64: Image64,
  65: Image65,
  66: Image66,
  67: Image67,
  68: Image68,
  69: Image69,
  70: Image70,
  71: Image71,
  72: Image72,
  73: Image73,
  74: Image74,
  75: Image75,
  76: Image76,
  77: Image77,
  78: Image78,
  79: Image79,
  80: Image80,
  81: Image81,
  82: Image82,
  83: Image83,
  84: Image84,
  85: Image85,
  86: Image86,
  87: Image87,
  88: Image88,
  89: Image89,
  90: Image90,
  91: Image91,
  92: Image92,
  93: Image93,
  94: Image94,
  95: Image95,
  96: Image96,
  97: Image97,
  98: Image98,
  99: Image99,
  100: Image100
}

export default images
