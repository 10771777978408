import { Stats } from '../../store/statsSlice'

/**
 * Wombat API base url
 */
const baseUrl = 'https://api.getwombat.io'

/**
 * Fetch the stats about the dynamic nfts contract
 */
export async function fetchStats(): Promise<Stats> {
  const endpoint = new URL('/dynamic-nfts/statistics', baseUrl)

  const params = new URLSearchParams({
    contract: '0x939821Fd096b4e4F67f369AF67cf9411b1A28160',
  })
  endpoint.search = params.toString()

  const response = await fetch(endpoint)
  return await response.json()
}
