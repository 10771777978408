import React, { FC } from 'react'

import styles from './Legal.module.scss'

/**
 * Legal information for the project
 */
type LegalData = {
  /**
   * The name i.e. text of the legal link
   */
  name: string
  /**
   * The link address of the legal information
   */
  url: string
}

/**
 * Data for displaying the legal links
 */
const LEGAL_DATA: LegalData[] = [
  {
    name: 'Terms & Conditions',
    url: 'https://womplay.io/terms-conditions'
  },
  {
    name: 'Legal Notice',
    url: 'https://womplay.io/imprint'
  },
  {
    name: 'Privacy Policy',
    url: 'https://womplay.io/privacy'
  }
]

/**
 * UI component for showing a link to a piece of legal information
 */
const Legal: FC<LegalData> = props => (
  <a href={props.url} className={styles['link']} target='_blank'><p>{props.name}</p></a>
)

/**
 * UI section containing links to legal information for the project
 */
export const Legals: FC = () => (
  <div className={styles['legals']}>
    {LEGAL_DATA.map(legal => <Legal key={legal.name} name={legal.name} url={legal.url}/>)}
  </div>
)
