import cl from 'classnames'
import React, { FC } from 'react'

import styles from './BenefitsTable.module.scss'

/**
 * The benefits recieved in the wombat ecosystem from owning various levels of tree NFT
 */
const benefits = [
  {
    label: 'Level',
    values: ['1', '2', '3', '4', '5', '10', '20', '50', '100']
  },
  {
    label: 'Cumulative XP',
    values: ['0', '104', '214', '329', '450', '1150', '3172', '20332', '246910']
  },
  {
    label: 'Mining Power',
    values: ['750', '825', '900', '975', '1050', '1425', '2175', '4425', '8175']
  },
  {
    label: 'Reward Power',
    values: ['1.00', '2.83', '5.20', '8.00', '11.18', '31.62', '89.44', '353.55', '1000']
  },
]


/**
 * A UI component displaying the benefits of owning dynamic NFTs
 */
export const BenefitsTable: FC = () => (
  <section id='benefits' role='region' aria-labelledby='benefits-heading'>
    <h2 id='benefits-heading'>Tree Level-Up overview</h2>
    <table className={cl(styles['table'], styles['table--horizontal'])}>
      <tbody>
        {benefits.map(benefit => {
          return (
            <tr key={benefit.label}>
              <th scope='row'>{benefit.label}</th>
              {benefit.values.map(val => <td key={val}>{val.toLocaleString()}</td>)}
            </tr>
          )
        })}
      </tbody>
    </table>
    <table className={cl(styles['table'], styles['table--vertical'])}>
      <tbody>
        <tr>{benefits.map(benefit => <th key={benefit.label}>{benefit.label}</th>)}</tr>
        {benefits[0].values.map((val, i) => {
          return (
            <tr key={val}>
              <td>{benefits[0].values[i]}</td>
              <td>{benefits[1].values[i]}</td>
              <td>{benefits[2].values[i]}</td>
              <td>{benefits[3].values[i]}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </section>
)
