@import '../../assets/styles/medias';
@import '../../assets/styles/variables';

.table {
  margin: 10px auto;
  border-collapse: collapse;
  margin-top: 16px;
  width: 100%;
  table-layout: fixed;

  @include tablet {
    margin-top: 24px;
  }

  @include desktop {
    margin-top: 36px;
  }

  @include desktop-xl {
    margin-top: 48px;
  }

  tr {
    width: 100%;
    &:not(:last-child) {
      border-bottom: 0.1rem solid var(--colorWhiteDisabled);
    }
  }

  th {
    text-align: left;
    font-weight: 700;
    font-size: 12px;
    
    @include tablet {
      font-size: 14px;
      font-weight: 600;
    }

    @include desktop {
      font-size: 16px;
    }
  }

  th, td {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  td {
    color: var(--colorTextSecondary);
    font-size: 12px;
    font-weight: 400;
    text-align: left;

    @include tablet {
      font-size: 14px;
    }

    @include desktop {
      font-size: 16px;
    }
  }

  &--horizontal {
    display: none;
    text-align: left;

    @include tablet {
      display: table;
      justify-content: center;
    }

    th {
      width: 150px;

      @include desktop {
        width: 175px;
      }
    }
  }

  &--vertical {
    @include tablet {
      display: none;
    }
  }
}
