import React, { FC, useState } from 'react'
import { features } from '../../feature-flags/feature-flags'

import styles from './TestingPanel.module.scss'

/**
 * A UI component for toggling application states in non-production environments
 */
export const TestingPanel: FC = () => {
  const [open, setOpen] = useState(true)

  if (!features.isTestingPanelEnabled()) {
    return null
  }

  return (
    <div className={styles['testing-panel']}>
      <span className={styles['top-bar']}>
        <p>Don't panic, this is not enabled for prod</p>
        <button onClick={() => setOpen(!open)}>{open ? 'close' : 'open'}</button>
      </span>
      {open && <div id='content'></div>}
    </div>
  )
}
