import cl from 'classnames'
import React, { FC } from 'react'
import { Socials } from './Social'
import { Legals } from './Legal'

import styles from './Footer.module.scss'

/**
 * UI footer component
 */
export const Footer: FC = () => (
  <section role='region' aria-label='footer' className={cl('default-section', styles['footer'])}>
    <Socials/>
    <Legals/>
  </section>
)
