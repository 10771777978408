@import '../assets/styles/variables';
@import '../assets/styles/medias';

.wallet-cta {
  display: flex;
  gap: 8px;
  background-color: var(--colorLightGreyPurple);
  height: 48px;
  padding: 8px;
  border-radius: 24px;
  height: 36px;
  
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
  }

  @include desktop-xl {
    height: 48px;
  }

  .icon {
    width: 20px;
    height: 20px;

    @include desktop-xl {
      width: 32px;
      height: 32px;
    }
  }
  
  .user-address {
    display: none;

    @include tablet {
      display: unset;
    }
  }
}

.disconnect-btn {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: var(--colorSurfaceDark);
  height: 36px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  padding: 0 24px;
  margin-top: 8px;
  margin-right: 32px;
  color: var(--colorHighlight);
  // @include text-small(left, 700);
  z-index: 1;
  right: 0;

  @include desktop-xl {
    height: 48px;
  }

  @include tablet {
    right: unset;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

.temporary-div {
  width: 100px;
}
