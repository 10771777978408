import cl from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import styles from './Button.module.scss'

type Props = PropsWithChildren & {
  /**
   * The function to execute on click
   */
  onClick: () => void
  /**
   * The style of the button
   */
  type?: 'primary' | 'secondary'
  /**
   * If the button is disabled
   */
  disabled?: boolean
  /**
   * An additional classname
   */
  extraClassName?: string
}

/**
 * The UI component for a basic button
 */
export const Button: FC<Props> = props => (
  <button
    className={cl(styles['button'], props.type ? styles[props.type] : '', props.extraClassName ?? '')}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </button>
)
