.testing-panel {
  position: absolute;
  padding: 8px;
  top: 30;
  right: 0;
  z-index: 100;
  background-color: cadetblue;
  width: 400px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: darkblue;
    padding: 5px;
    border-radius: 20%;
    cursor: pointer;
    margin: 2px;
  }
}

