import cl from 'classnames'
import React, { FC, useMemo } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { OwnedTokens } from './OwnedTokens'

import styles from './Collection.module.scss'

/**
 * Page section for displaying the dynamic tokens the user owns
 */
export const Collection: FC = () => {
  const { connected, address } = useAppSelector(state => state.user)
  const tokens = useAppSelector(state => state.tokens.tokens)

  /**
   * Whether the user owns any tokens in the collection
   */
  const isEmpty = tokens.length === 0

  /**
   * The UI component to display the user's owned tokens
   */
  const Gallery = useMemo(() => {
    if (isEmpty) {
      return <div className={styles['empty-message']}>You don't own any Treevolve yet.</div>
    } else {
      return <OwnedTokens tokens={tokens} address={address}/>
    }
  }, [isEmpty, tokens])


  // Only render the component if the user wallet is connected
  if (connected !== 'YES') {
    return null
  }

  return (
    <section id='collection' role='region' aria-labelledby='collection-heading'
      className={cl(styles['collection'], isEmpty ? styles['collection--empty'] : '' )}>
      <h2 id='collection-heading' className={styles['section-title']}>My collection</h2>
      {Gallery}
    </section>
  )
}
