import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Frame } from '../../assets/images/Frame'
import { useViewportSize } from '../../hooks/useViewportSize'

import styles from './Frame.module.scss'

/**
 * The number of pixels by which the frame is bigger than the image it contains. This is used for
 * both height and width.
 */
const ADDITIONAL_FRAME_PIXELS = 22

type Props = PropsWithChildren & {
  /**
   * Whether the content has loaded
   */
  loaded: boolean
}

/**
 * A UI component to frame elements which adjusts to the element dimensions
 */
export const DynamicFrame: FC<Props> = props => {
  const viewport = useViewportSize()
  const childRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({
    width: ADDITIONAL_FRAME_PIXELS, height: ADDITIONAL_FRAME_PIXELS
  })

  useEffect(() => {
    const rectangle = childRef.current?.getBoundingClientRect()
    if (rectangle)
      setDimensions({
        width: rectangle.width + ADDITIONAL_FRAME_PIXELS,
        height: rectangle.height + ADDITIONAL_FRAME_PIXELS
      })
  }, [childRef, viewport, props.loaded])

  return (
    <div className={styles['frame-container']}>
      <Frame width={dimensions.width} height={dimensions.height}/>
      <div ref={childRef} className={styles['image-container']}>
        {props.children}
      </div>
    </div>
  )
}
